

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyANjFbF89NdTyFsWfeBAWu7GRSzrCF6zxY",
  authDomain: "church-pwa.firebaseapp.com",
  projectId: "church-pwa",
  storageBucket: "church-pwa.appspot.com",
  messagingSenderId: "921056446983",
  appId: "1:921056446983:web:8115f39dbb69e4a43cf8a2",
  measurementId: "G-7MCKHWFR0T"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
