const config = {
    siteUrl: "https://api.destinyliferc.org",

    errors: {
        generic: 'Something went wrong, please try later',
        noBalance: 'Insufficient balance',
        invalidPhone: 'Phone number is invalid',
        invalidPhoneCode: 'Code provided is invalid',

    },
    games: {
        bongomillions: {
            name: "Bongomillions",
            savePath: 'millions_buy_data'
        },
        bongoball: {
            name: "Bongoball",
            savePath: 'ball_buy_data'
        }
    }
}
module.exports = config;

//"http://localhost:5201/anioma-gas/us-central1",//