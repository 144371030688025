var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-4",attrs:{"shaped":""}},[_c('v-card-title',[_vm._v("Registeration")]),_c('v-card-text',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"4"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","clearable":"","label":"Search Email","single-line":"","hide-details":""},on:{"click:append":_vm.searchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('div',[_c('v-menu',{attrs:{"top":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"primary","rounded":"","dark":""}},on),[_vm._v(_vm._s(_vm.dateRangeText))])]}}])},[_c('v-list',_vm._l((_vm.menuItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.itemSelected(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.display))])],1)}),1)],1),_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","top":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.setRange}},[_vm._v("OK")])],1)],1)],1)])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header,"search":_vm.search,"items":_vm.transactions,"server-items-length":_vm.count,"loading":_vm.loading},on:{"update:options":_vm.getOptions},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","light":"","outlined":"","color":"blue"}},[_vm._v(_vm._s(_vm.formartCurrency(item.amount, false, true)))])]}},{key:"item.remainingDraws",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","light":"","outlined":"","color":"green"}},[_vm._v(_vm._s(item.remainingDraws))])]}},{key:"item.completedDraws",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","light":"","outlined":"","color":"orange"}},[_vm._v(_vm._s(item.completedDraws ? item.completedDraws : 0))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(_vm.formatDateMed(item.createdAt))+" : "+_vm._s(_vm.formatTime(item.createdAt))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showDetail(item)}}},[_vm._v("mdi-open-in-new")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'pending')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alarm")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"580"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('RegDetail',_vm._g({ref:"dialog"},_vm.handlers))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }