<template>
  <v-container>
    <v-card class="pa-4" shaped>
      <v-card-title>Events</v-card-title>
      <!-- <v-card-title class="subtitle-2">Winners</v-card-title> -->
      <v-card-text>
        <v-data-table
          :headers="header"
          :search="search"
          :items="transactions"
          :server-items-length="count"
          :loading="loading"
          class="elevation-0"
          v-on:update:options="getOptions"
        >
          <!-- <template v-slot:item.subscription.fee="{ item }">
            <v-chip
              small
              light
              outlined
              color="green"
              class="font-weight-bold"
              >{{
                formartCurrencyDecimal(item.subscription.fee, false, true)
              }}</v-chip
            >
          </template>

          <template v-slot:item.subscription.duration="{ item }">
            <v-chip
              small
              light
              outlined
              color="orange"
              class="font-weight-bold"
              >{{ `${item.subscription.duration} Month` }}</v-chip
            >
          </template> -->

          <template v-slot:item.bannerUrl="{ item }">
            <img height="60" :src="item.bannerUrl" alt="" srcset="" />
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon class="mr-16" color="red" @click="openWarn(item)"
              >mdi-delete</v-icon
            >
            <v-icon
              v-if="!item.selected"
              class="mr-0"
              color="primary"
              @click="selectEvent(item)"
              >mdi-eye</v-icon
            >
            <v-icon v-else class="mr-0" @click="selectEvent(item)" color="green"
              >mdi-fire</v-icon
            >
          </template>

          <template v-slot:item.status="{ item }">
            <v-icon v-if="item.status === 'pending'" color="red"
              >mdi-alarm</v-icon
            >
            <v-icon v-else color="green">mdi-check-bold</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-fab-transition>
        <v-btn
          @click="addEvent"
          color="pink"
          :disabled="loading"
          fab
          dark
          small
          absolute
          bottom
          right
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="dialog" max-width="620px">
      <EventEdit ref="dialog" v-on="handlers" />
    </v-dialog>
    <v-dialog v-model="warnDlg" max-width="280">
      <v-card class="pa-6">
        <v-card-text>
          <v-row><div class="title">Remove Event?</div></v-row>
          <v-row
            ><div class="caption mt-2 mb-4">
              This cannot be reversed!
            </div></v-row
          >
          <v-row class="justify-space-between">
            <v-btn @click="warnDlg = false" outlined color>Cancel</v-btn>
            <v-btn dark :loading="loading" color="red" @click="remove"
              >Remove</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EventEdit from "./eventEdit.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { EventEdit },
  data() {
    return {
      handlers: {
        "close-dialog": this.closeDiag,
        refresh: this.refresh,
      },
      selectedItem: {},
      warnDlg: false,
      last: ["none"],
      lastPage: 0,
      dialog: false,
      loading: false,
      options: null,
      search: null,
      dates: ["2019-09-10", "2019-09-20"],
      menu: false,
      dateMenu: false,
      datesForDisplay: null,
      // options: null,
      // search: null,
      startDate: null,
      stopDate: null,
      // dialog: false,
      selected: { display: "This Month", id: "month" },
      menuItems: [
        { display: "Today", id: "today" },
        { display: "This Week", id: "week" },
        { display: "This Month", id: "month" },
        { display: "This Quarter", id: "quarter" },
        { display: "This Year", id: "year" },
        { display: "Range Select", id: "range" },
        { display: "All", id: "all" },
      ],
      header: [
        {
          text: "Title",
          align: "left",
          sortable: false,
          value: "title",
        },
        {
          text: "Subtitle",
          align: "left",
          sortable: false,
          value: "subtitle",
        },
        {
          text: "Days",
          align: "left",
          sortable: false,
          value: "days",
        },
        {
          text: "Banner",
          align: "left",
          sortable: false,
          value: "bannerUrl",
        },
        {
          text: "Description",
          align: "left",
          sortable: false,
          value: "description",
        },

        {
          text: "",
          align: "left",
          value: "action",
          sortable: false,
        },
      ],
      itemsPerPage: 10,
      currentPage: 0,
      count: 0,
      sortby: "name",
      sortDesc: true,
      transactions: [],
    };
  },
  created: function () {
    // console.log("---<>", this.game);
  },
  computed: {
    ...mapGetters([
      "formatTime",
      "formatDateMed",
      "formartCurrency",
      "formatDateLong",
      "getEndDate",
      "formatDateRelative",
      "getDayStartAndEnd",
      "getSortedDates",
      "formartCurrencyDecimal",
    ]),
    dateRangeText() {
      switch (this.selected.id) {
        case "range":
          if (this.datesForDisplay === 1) {
            return this.datesForDisplay;
          } else {
            return `${this.datesForDisplay[1]} to ${this.datesForDisplay[0]}`;
          }

        default:
          return this.selected.display;
      }
    },
  },
  methods: {
    ...mapActions("api", ["httpGet", "httpPost"]),
    ...mapActions(["showMessage"]),
    refresh() {
      this.getOptions(this.options);
      this.dialog = false;
    },
    openWarn(item) {
      this.selectedItem = item;
      this.warnDlg = true;
    },
    closeDiag() {
      this.dialog = false;
    },
    async selectEvent(item) {
      try {
        this.loading = true;
        await this.httpPost({
          withAuth: true,
          url: item.selected ? "/admin/deselect_event" : "/admin/select_event",
          data: { id: item._id },
        });
        this.refresh();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showMessage({
          text: "Something went wrong",
          error: true,
        });
      }
    },
    addEvent() {
      this.dialog = true;
      setTimeout(() => {
        this.$refs.dialog.eventModel = {
          description: "",
          subtitle: "",
          days: "",
          title: "",
        };
      }, 100);
    },
    searchInput() {
      this.options.search = this.search.trim();
      this.getOptions(this.options);
    },
    itemSelected(item) {
      this.selected = item;
      if (item.id === "range") {
        this.dates = [new Date().toISOString().substr(0, 10)];
        this.datesForDisplay = this.dates;
        this.dateMenu = true;
        return;
      }
      let end = this.getEndDate(item.id); //this.stopDate;
      this.dates = [
        new Date().toISOString().substr(0, 10),
        end.toISOString().substr(0, 10),
      ];
      if (item.id === "all") {
        this.options.start = "all";
        this.options.end = "all";
      } else {
        this.options.start = new Date();
        this.options.end = end;
      }
      try {
        this.getOptions(this.options);
      } catch (error) {
        this.showMessage({ text: error.message, error: true });
      }
    },
    remove: async function () {
      try {
        this.loading = true;
        await this.httpPost({
          withAuth: true,
          url: "/admin/remove_event",
          data: { id: this.selectedItem._id },
        });
        this.showMessage({ text: "Event Removed", error: false });
        this.getOptions(this.options);
        this.warnDlg = false;
      } catch (error) {
        this.showMessage({ text: error.response.data.message, error: true });
      }
      this.loading = false;
    },
    getOptions: async function (options) {
      this.options = options;
      this.loading = true;
      try {
        if (this.search) {
          this.options.search = this.search.trim();
        }
        // this.options.start = this.startDate;
        // this.options.end = this.stopDate;
        if (this.options.start !== "all") {
          if (!this.options.start || !this.options.end) {
            this.options.start = new Date();
            this.options.end = this.getEndDate("month");
          }
          this.dates = [
            new Date().toISOString().substr(0, 10),
            this.options.end.toISOString().substr(0, 10),
          ];
        }
        this.itemsPerPage = options.itemsPerPage;
        this.currentPage = options.page - 1;
        this.sortby =
          options.sortBy.length === 0 ? "createdAt" : options.sortBy[0];
        this.sortDesc =
          options.sortDesc.length === 0 ? true : options.sortDesc[0];
        this.search = options.search;
        let lastId = "none";
        if (this.currentPage >= this.lastPage) {
          lastId = this.last[this.currentPage];
        } else {
          lastId = this.last[this.lastPage - 1];
        }
        let resp = await this.httpGet({
          withAuth: true,
          url: "/admin/get_events",
          data: {
            limit: this.itemsPerPage,
            page: this.currentPage,
            sortBy: this.sortby,
            sortDesc: this.sortDesc,
            search: this.search,
            end: this.options.start,
            start: this.options.end,
            last: lastId,
          },
        });

        this.lastPage = this.currentPage;
        if (resp.data.data.length) {
          this.last[this.currentPage + 1] =
            resp.data.data[resp.data.data.length - 1].id;
        }
        console.log(resp.data);
        resp.data.data.forEach((item) => {
          if (item.status) {
            // item._type = `${item.bank}/${item.channel}`;
            // item.status = item.status[0].toUpperCase() + item.status.slice(1);
            // item.game = item.game[0].toUpperCase() + item.game.slice(1);
          }
        });

        this.transactions = resp.data.data;
        this.count = resp.data.count;
        // console.log("resp", resp.data);
      } catch (error) {
        console.error(error);
        this.showMessage({
          error: true,
          text: "Could not load preview, please refresh page",
        });
      }
      this.loading = false;
    },
  },
  watch: {
    search: function (val) {
      if (!val) {
        this.options.search = this.search;
        this.getOptions(this.options);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.txt {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>