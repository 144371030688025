<template>
  <div>
    <v-card class="pa-4" max-width="620" min-height="300">
      <v-card-title class="panel-title"> Edit Event </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="eventModel">
        <v-form
          ref="courseForm"
          v-model="valid"
          @submit.prevent="submit"
          action
        >
          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                label="Title"
                v-model="eventModel.title"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>
              <v-text-field
                label="Subtitle"
                v-model="eventModel.subtitle"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>
              <v-text-field
                label="Days"
                v-model="eventModel.days"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-file-input
                class="mb-6"
                show-size
                truncate-length="18"
                :disabled="loading"
                :rules="photoRules"
                accept="image/png, image/jpeg"
                placeholder="Event Banner"
                prepend-icon="mdi-camera"
                v-model="eventModel.banner"
              ></v-file-input>
              <v-textarea
                label="Description"
                v-model="eventModel.description"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                rows="2"
                type="text"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-n4"></v-divider>

          <v-row dense class="align-center justify-space-between">
            <v-col cols="12" xs="12" sm="4" class="mt-4">
              <v-btn
                :loading="loading"
                x-large
                color="primary"
                @click="submit"
                block
                >Save</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="mr-0 mt-4">
              <v-btn @click="$emit('close-dialog')" x-large outlined block
                >Close</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <!-- <v-row></v-row>
        <v-row class="mt-6 justify-end">
          <v-btn @click="$emit('close-dialog')" large raised color="primary"
            >Save</v-btn
          >
        </v-row> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../utils/config";
export default {
  props: ["game"],
  computed: {
    ...mapGetters(["formartCurrency", "formatDateMed"]),
  },
  created() {},
  methods: {
    ...mapActions("api", ["httpGet", "httpPost", "httpPostMP"]),
    ...mapActions(["showMessage"]),
    save(date) {
      this.$refs.menu.save(date);
    },

    validate: function () {
      this.$refs.courseForm.validate();
    },
    saveEvent: async function () {
      let formData = new FormData();
      formData.append("videoFiles", this.eventModel.banner);
      formData.append("subtitle", this.eventModel.subtitle);
      formData.append("days", this.eventModel.days);
      formData.append("description", this.eventModel.description);
      formData.append("title", this.eventModel.title);

      // console.log("posting===", formData);
      console.log(this.eventModel);
      let params = await this.httpPostMP({
        withAuth: true,
        url: "/admin/save_event",
        data: formData,
      });
      return params.data;
    },
    submit: function () {
      this.validate();
      this.loading = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Please fix issues with event data",
            error: true,
          });
          this.loading = false;
        } else {
          try {
            await this.saveEvent();
            this.loading = false;
            this.showMessage({ text: "Event saved", error: false });
            this.$emit("refresh");
          } catch (error) {
            console.error(error);
            this.loading = false;
            let errMsg = config.errors.generic;
            this.showMessage({
              text: errMsg,
              error: true,
            });
          }
        }
      }, 100);
    },
    getCat: async function () {
      this.course = {
        _id: this.eventModel.courseId,
        name: this.eventModel.courseName,
      };
      this.category = {
        _id: this.eventModel.categoryId,
        name: this.eventModel.categoryName,
      };
      this.topic = {
        _id: this.eventModel.topicId,
        name: this.eventModel.topicName,
      };
      this.loading = true;
      try {
        let params = await this.httpGet({
          withAuth: true,
          url: "/admin/get_all_categories",
          data: {},
        });
        this.loading = false;
        this.categories = params.data;
        console.log("--->>courses", this.eventModel, params.data);
      } catch (error) {
        this.loading = false;
        let errMsg = config.errors.generic;
        this.showMessage({
          text: errMsg,
          error: true,
        });
      }
    },
  },
  watch: {},
  data() {
    return {
      search: "",
      eventModel: {
        description: "",
        subtitle: "",
        days: "",
        title: "",
      },
      menu: false,
      loading: false,
      busy: false,
      nameRules: [(v) => !!v || "This Field is required"],
      photoRules: [
        (v) => {
          if (v) {
            if (v.size < 2000000) {
              return true;
            } else {
              return "Photo size should be less than 2 MB!";
            }
          } else {
            return "This Field is required";
          }
        },
      ],

      valid: false,
    };
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

