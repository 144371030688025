import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'Home',
    component: Home,
    props: true,
    children: [
      {
        path: "/",
        name: 'Events',
        component: () => {
          return import(/* webpackChunkName: "Videos" */ '../components/events/eventsView.vue');
        },
      },

      {
        path: "regs",
        name: 'Regs',
        component: () => {
          return import(/* webpackChunkName: "Regs" */ '../components/regs/regsView.vue');
        },
      },

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => {
      return import(/* webpackChunkName: "login" */ '../views/login.vue');
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
